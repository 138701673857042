import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const WaFdFoundationAndCommunityGiving = () => {
  return (
    <>
      <section className="container border-bottom-3 border-success pb-2">
        <h1 className="text-success" id="wafd-foundation-title">
          Washington Federal Foundation and Community Giving
        </h1>
        <div className="row">
          <div className="col-md-8">
            <p>
              Founded in 2009, the Washington Federal Foundation's purpose is to facilitate direct giving to
              community-based nonprofits serving the needs of people with low-and moderate-incomes. Each year we commit
              over $1 million to the communities we serve.
            </p>
          </div>
        </div>
      </section>
      <section className="container pb-0">
        <div className="row">
          <div className="col-md-6">
            <StaticImage
              src="../../images/washington-federal-foundation-ferndale-food-bank-600.jpg"
              alt="WaFd employees presenting a check at the Foundation Ferndale Food Bank"
              placeholder="blurred"
              quality="100"
              className="mb-3"
            />

            <h4 className="text-success font-weight-semi-bold">Washington Federal Foundation</h4>
            <p>
              As a bank dedicated to promoting home ownership, the focuses on programs and causes that help provide
              affordable housing for low-and-moderate-income individuals. The Foundation also provides support for
              social agencies and nonprofits that serve the needs in our communities, including seniors, youth
              organizations, and low income families.
            </p>
            <p>We direct giving to the following areas:</p>
            <ul>
              <li>Housing and community development</li>
              <li>Social services and basic needs for seniors, youth and low-income families</li>
              <li>Financial literacy</li>
            </ul>
            <p>Average grants range from $1,000-$5,000</p>
            <p>
              Since its inception, the Foundation has given nearly <strong>$8.1M</strong> to local charities across our
              nine-state footprint.
            </p>
            <p>
              Please visit our{" "}
              <Link id="washington-federal-foundation-link" to="/about-us/washington-federal-foundation">
                Foundation page
              </Link>{" "}
              for more information.
            </p>
          </div>
          <div className="col-md-6">
            <StaticImage
              src="../../images/united-way-day-of-caring-600.jpg"
              alt="WaFd employees volunteering for United Way, United 4 Vegas"
              placeholder="blurred"
              quality="100"
              className="mb-3"
            />

            <h4 className="text-success font-weight-semi-bold">United Way</h4>
            <p>
              WaFd Bank matches employee contributions made to United Way agencies in all nine states:{" "}
              <span className="text-green-60 font-weight-bold">
                For fiscal 2023, total pledges from colleagues amounted to $376,392.91 in charitable giving. WaFd
                matched $376,392.91 in donations to United Way agencies for a total of $746,585.83.
              </span>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default WaFdFoundationAndCommunityGiving;
