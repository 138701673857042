import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const FinancialLiteracy = () => {
  return (
    <>
      <section className="container border-bottom-3 border-success pb-2">
        <h1 className="text-success" id="financial-literacy-title">
          Financial Literacy
        </h1>
        <div className="row">
          <div className="col-md-8">
            <p>
              We believe in providing people with financial education programs that make their lives better. Financial
              education can help foster stability for individuals, families and entire communities; the more people know
              about money management, the more likely they are to improve and maintain their financial well-being.
            </p>
          </div>
        </div>
      </section>
      <section className="container pb-0">
        <h3 className="text-green-60 font-weight-semi-bold">WaFd invests in the following literacy programs</h3>
        <div className="row">
          <div className="col-md-6">
            <StaticImage
              src="../../images/save-at-school-program-600.jpg"
              alt="Teacher assisting elementary school kids working on laptops"
              placeholder="blurred"
              quality="100"
              className="mb-3"
            />

            <h3 className="text-green-60 font-weight-semi-bold">Save at School program</h3>
            <p>
              Our Save at Schools program helps middle and elementary school students learn the habit of saving by
              making deposits during our &ldquo;bank days.&rdquo; We match the initial $5 opening deposit and students
              can earn 5% on their first $100 deposit. Email{" "}
              <a id="ask-wafd-mail-cta" href="mailto:ask@wafd.com">
                ask@wafd.com
              </a>{" "}
              to launch a Save at Schools program in your area.
            </p>
            <StaticImage
              src="../../images/wafd-junior-achievement-600.jpg"
              alt="WaFd employees volunteering to support Junior Achievement "
              placeholder="blurred"
              quality="100"
              className="mb-3"
            />

            <h3 className="text-green-60 font-weight-semi-bold">Junior Achievement</h3>
            <p>
              We aspire to help young people succeed and positively impact the communities we serve. That's why we
              partner with Junior Achievement to provide financial support and employee volunteers to help youth learn
              financial wellness and build the skills they need to launch successful futures.
            </p>
          </div>
          <div className="col-md-6">
            <StaticImage
              src="../../images/financial-literacy-600.jpg"
              alt="Happy female teenager using a computer in the computer lab"
              placeholder="blurred"
              quality="100"
              className="mb-3"
            />

            <h3 className="text-green-60 font-weight-semi-bold">Financial Literacy</h3>
            <p>
              We partner with Banzai, an award-winning, interactive financial content platform to provide free financial
              literacy and education to Title One schools across out nine-state footprint. We help all ages from 8 to 88
              learn to create a budget, get out of debt, buy a home, save for college, learn about inflation, save for
              retirement, build credit, navigate online security and more! We also offer Banzai to our non-profit
              partners through the Washington Federal Foundation ensuring the communities we support have the tools they
              need for a brighter future. If your school or non-profit is looking to include this curriculum in your
              organization, please{" "}
              <Link id="financial-literacy-contact-us-link" to="/about-us/contact-us">
                contact us
              </Link>
              !
            </p>
            <StaticImage
              src="../../images/third-party/banzai-logo-255.jpg"
              alt="Banzai Logo"
              placeholder="blurred"
              quality="100"
              className="mb-3"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default FinancialLiteracy;
