import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const CommunityDevelopment = () => {
  return (
    <>
      <section className="container border-bottom-3 border-success pb-2">
        <h1 className="text-success" id="community-development-title">
          Community Development
        </h1>
        <div className="row">
          <div className="col-md-8">
            <p>
              We recognize the impact of our activities on the lives of people around us. We are continuously committed
              to serving our communities, clients, and employees. Throughout our history, we have focused on giving back
              to our communities and have developed a variety of programs to help make a difference in the lives of
              those around us.
            </p>
          </div>
        </div>
      </section>
      <section className="container pb-0">
        <div className="row">
          <div className="col-md-6">
            <StaticImage
              src="../../images/community-development-new-home-600.jpg"
              alt="Excited young couple standing in front of new home"
              placeholder="blurred"
              quality="100"
              className="mb-3"
            />

            <h4 className="font-weight-semi-bold">In Housing and Community Development </h4>
            <p>
              In 2023, WaFd invested over $76 million in community development loans. Of this, over $49 million was
              invested toward affordable housing across our nine state footprint.
            </p>
            <p>
              2022, WaFd Bank devoted $203,425,455 toward community development lending. In addition, $102,100,000 in
              housing tax credits were provided along with $61,800,000 in investments made to other affordable housing
              projects, totaling $367,325,455.
            </p>
            <p>
              In 2021, over $289,000,000 was invested toward community development lending and affordable housing
              investments. This resulted in 47 loans totaling $165,000,000 to support affordable home ownership for
              low-income borrowers within our nine states.
            </p>
          </div>
          <div className="col-md-6">
            <StaticImage
              src="../../images/community-development-small-business-flex-fund-600.jpg"
              alt="Smiling, Muslim businesswomen working together"
              placeholder="blurred"
              quality="100"
              className="mb-3"
            />

            <p>
              In 2020 we created the Small Business Flex Fund that aided 365 small businesses with lifeline loans
              totaling $12,500,000 to ensure these business owners could bridge the gap between the beginning of the
              pandemic and when government assistance became available.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default CommunityDevelopment;
