import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const EnvironmentalResponsibilityAndSocialManagement = () => {
  return (
    <>
      <section className="container border-bottom-3 border-success pb-2">
        <div className="row">
          <div className="col-md-8">
            <h1 className="text-success" id="er-srm-title">
              Environmental Responsibility and Social Risk Management
            </h1>
            <p>
              WaFd Bank's corporate social and environmental responsibility commitment arises from the impact of our
              activities on people's lives and society. To fulfill this commitment, WaFd Bank integrates social,
              environmental and ethical concerns, as well as those regarding human rights and the needs of its
              stakeholders, into its daily business activities and approach to stakeholder relationships.
            </p>
          </div>
        </div>
      </section>
      <section className="container pb-0">
        <div className="row">
          <div className="col-md-6">
            <p className="font-weight-bold text-green-60">Through all our interactions, WaFd Bank will:</p>
            <ul>
              <li>
                Carry out its banking activities in a responsible manner, placing the financial needs of its clients and
                economic health of its communities at the core of its strategic focus.
              </li>
              <li>
                Maximize the creation of sustainable and shared value for its shareholders, employees and stakeholders,
                including contributing to the broader benefit of society.
              </li>
            </ul>
            <StaticImage
              src="../../images/climate-change-600.jpg"
              alt="African-American father and daughter holding small seedling at a community garden"
              placeholder="blurred"
              quality="100"
              className="mb-3 "
            />

            <p>
              We acknowledge the impact of climate change, which may lead to new regulations as well as financial and
              reputational risks. We also realize there are new opportunities to find ways to mitigate those risks and
              open new doors to offsetting carbon footprints. We will embrace these opportunities and seek out new ways
              to invest in change to a lower carbon economy and a better future for us all.
            </p>
          </div>
          <div className="col-md-6">
            <StaticImage
              src="../../images/environmental-stewardship-600.jpg"
              alt="Man Riding in bike lane past city park"
              placeholder="blurred"
              quality="100"
              className="mb-3 "
            />

            <h3 className="text-green-60 font-weight-semi-bold">Environmental Stewardship</h3>
            <p>
              WaFd strives to focus on efficiency and long-term sustainability in our business processes through prudent
              use of resources, including renewable energy and waste reduction efforts. We also offer the following
              services and benefits to employees at our corporate headquarters in downtown Seattle:
            </p>
            <ul>
              <li>
                Free transit passes for all employees to encourage mass transit, including train, ferry, bus and light
                rail.
              </li>
              <li>Secure, onsite bike storage and shower facilities for our biking community.</li>
              <li>
                Improved air quality through bi-polar ionization in our HVAC system to remove bacteria, viruses and
                pollutants.
              </li>
              <li>
                Flexible work policies that allow for a hybrid approach between office hoteling and work-from-home,
                reducing physical commutes and carbon footprints.
              </li>
              <li>
                Working to reduce total square footage of corporate and branch real estate across our footprint to save
                on energy costs and shrink our physical carbon footprint.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default EnvironmentalResponsibilityAndSocialManagement;
