import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Button from "../custom-widgets/button";

const WaFdWorkforce = () => {
  return (
    <>
      <section className="container border-bottom-3 border-success pb-2">
        <h1 className="text-success" id="wafd-workforce-title">
          WaFd Workforce
        </h1>
        <div className="row">
          <div className="col-md-8">
            <p>
              At WaFd Bank, we've taken the same long-term perspective with our employees as we have with our clients.
              We value each of our employees and invest in their development and wellbeing.
            </p>
          </div>
        </div>
      </section>
      <section className="container pb-0">
        <div className="row">
          <div className="col-md-6">
            <StaticImage
              src="../../images/thumbnail-careers-at-wafd-081324.jpg"
              alt="WaFd employees waving as a group in a WaFd Bank lobby"
              placeholder="blurred"
              quality="100"
              className="mb-3"
            />

            <h3 className="text-green-60 font-weight-semi-bold">A career at WaFd Bank</h3>
            <p>
              We value our employees by investing in a healthy work/life balance, competitive compensation packages, a
              vibrant team-oriented environment centered on professional service, and 360-degree, open communication
              with your colleagues.
            </p>
            <p className="font-weight-bold">A Career at WaFd Bank Offers:</p>
            <ul>
              <li> Paid time off and sick days</li>
              <li>Health benefits</li>
              <li>Stock options</li>
              <li>Bonus program</li>
              <li>Generous 401(k) match</li>
              <li>Life and AD&amp;D insurance</li>
              <li>Long-term disability</li>
              <li>Employee assistance program</li>
              <li>Pre-tax health and dependent-care spending plans</li>
              <li>Four weeks parental leave</li>
              <li>$5,000 per year in tuition reimbursement, up to $40,000 total</li>
            </ul>
          </div>
          <div className="col-md-6">
            <StaticImage
              src="../../images/best-employer-lisa-walt-600.jpg"
              alt="Walt mascot and WaFd employee smiling with heart cutout next to an America's Best Employers 2022 by Forbes badge"
              placeholder="blurred"
              quality="100"
              className="mb-3"
            />

            <h3 className="text-green-60 font-weight-semi-bold">We love coming to work and it shows!</h3>
            <p>
              In 2022, WaFd Bank was recognized as one of America's Best Employers by Forbes. We're proud of our
              employees and all that they do.
            </p>
            <Button
              text="Visit WaFd Careers"
              id="wafd-careers-cta"
              url="/about-us/banking-careers"
              class="btn-primary"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default WaFdWorkforce;
