import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const DiversityEquityAndInclusion = () => {
  return (
    <>
      <section className="container border-bottom-3 border-success pb-2">
        <h1 className="text-success" id="diversity-equity-inclusion-title">
          Diversity, Equity, and Inclusion
        </h1>
        <div className="row">
          <div className="col-md-8">
            <p>
              We are committed to supporting a diverse employee base, creating a culture of inclusion, equity, and
              empowerment, and bringing our full selves to work. Diversity can take many forms, including race,
              nationality, ethnicity, sexual orientation, religion, disability, gender, age, protected veteran status,
              or other distinguishing characteristics.
            </p>
          </div>
        </div>
      </section>
      <section className="container pb-0">
        <div className="row">
          <div className="col-md-6">
            <StaticImage
              src="../../images/diversity-inclusion-day-of-caring-600.jpg"
              alt="A diverse group of WaFd employees"
              placeholder="blurred"
              quality="100"
              className="mb-3"
            />

            <p className="mb-3">
              Our mission statement is &ldquo;We are committed to building diverse teams and creating a culture of
              inclusion, empowerment, and belonging.&rdquo; To achieve this, we've moved from the current single DEI
              council structure and replaced it with a DEI council consisting of 5 to 10 people in each region with each
              Regional President as the executive sponsor. Additionally, we've included a DEI council for our corporate
              support areas, with our Chief Consumer Banker and Chief Operations Officer as executive sponsors. This
              will help ensure our efforts are reflected across all nine states in which we reside.{" "}
            </p>
            <ul>
              <li>
                Partnering with a DEI Consultant to grow inclusive mind sets and help build stronger programs with
                sustainable strategies.
              </li>
              <li>
                Focusing on enhancing our approach to recruiting, hiring, and retention. As WaFd's mission statement
                says, &ldquo;diversity, equity and inclusion drive innovation and productivity, and allow WaFd to better
                serve our communities and each other.&rdquo;
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <p className="mb-3">
              Adding employee resource groups to support efforts across the Bank, with four to start:
            </p>
            <ul>
              <li>Black, Indigenous, People of Color (BIPOC)</li>
              <li>Emerging Leaders</li>
              <li>Working Parents</li>
              <li>LGBTQ+</li>
            </ul>
            <p className="mb-3">
              The Global Diversity and Inclusion Advisory Council is a commitment by senior executives to support the
              Regional and Corporate Support Councils to ensure that there is an enterprise-wide focus on diversity and
              inclusion. The Advisory Council:
            </p>
            <ul>
              <li>
                Provides strategic guidance for business and regional councils, as well as the broader D&I program.
              </li>
              <li>Facilitates D&I cross functional and enterprise-wide strategies.</li>
              <li>Ensures support is provided to the regional and back-office support D&I program strategies.</li>
              <li>Defines requirements for council membership (appointed and volunteer).</li>
            </ul>
            <p className="text-green-60 font-weight-bold mb-3">2,120 Team Members</p>
            <ul>
              <li>24% Non-White</li>
              <li>2.48% US Veteran</li>
              <li>5.77% Differently Abled</li>
              <li>68% Female</li>
              <li>32% Male</li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default DiversityEquityAndInclusion;
