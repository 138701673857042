import React from "react";
import { Link } from "gatsby";

import Icon from "../custom-widgets/icon";
import LogoSeattleStorm from "../../images/third-party/seattle-storm/seattle-storm-logo.svg";
import LogoSeattleKraken from "../../images/third-party/kraken/seattle-kraken-logo.svg";
import LogoSeattleSeawolves from "../../images/third-party/seattle-seawolves/seattle-seawolves-logo.svg";

const CommunityPartnerships = () => {
  return (
    <>
      <section className="container border-bottom-3 border-success pb-2">
        <h1 className="text-success" id="community-partnerships-title">
          Community Partnerships
        </h1>
        <div className="row">
          <div className="col-md-8">
            <p>
              At WaFd, we align with partners that share and exhibit the same vales as we do. Our partners complement
              our passions and strengths and help us build stronger and more vibrant communities. Each partnership
              includes donations to community-based organizations and foundations that enrich the communities we serve.
            </p>
          </div>
        </div>
      </section>
      <section className="container pb-0">
        <div className="row">
          <div className="col-md-6">
            <div className="text-center mb-2">
              <img src={LogoSeattleKraken} alt="Seattle Seawolves Logo" width={180} height={180} />
            </div>
            <h5 className="text-center">
              <Link id="seattle-kraken-link" to="/about-us/wafd-sports/seattle-kraken" className="text-decoration-none">
                Seattle Kraken
                <Icon name="arrow-right" class="ml-2"></Icon>
              </Link>
            </h5>
            <p>
              We have joined the Seattle Kraken as a Founding Partner as both organizations share the fundamentals of
              integrity, hard work, and innovation. We are excited to help bring hockey back to Seattle and give back
              through their{" "}
              <a
                rel="noopener noreferrer"
                target="_blank"
                id="one-roof-foundation-link"
                href="https://onerooffoundation.org/"
              >
                One Roof Foundation
              </a>{" "}
              in efforts to continue to build the most diverse and inclusive hockey culture in the country, if not the
              world.
            </p>
          </div>
          <div className="col-md-6">
            <div className="text-center">
              <img src={LogoSeattleStorm} alt="Seattle Seawolves Logo" width={180} height={180} />
            </div>
            <h5 className="text-center">
              <Link id="seattle-storm-link" to="/about-us/wafd-sports/seattle-storm" className="text-decoration-none">
                Seattle Storm
                <Icon name="arrow-right" class="ml-2"></Icon>
              </Link>
            </h5>
            <p>
              WaFd and the Seattle Storm will launch Fit Futures together, a community initiative that provides kids
              with equal access to physical activity and financial education, set to begin in the fall of 2023. Three
              Title 1 schools will receive $5,000 each towards the purchase of new PE equipment and supplies in a
              celebratory ceremony to include the presence of Storm mascot Doppler and a Storm VIP guest. Each of these
              three schools will also receive 300 tickets to a regular season Storm game for kids and their families.
              Additionally, WaFd sponsors a half court shootaround challenge with Storm players benefitting a local
              BIPOC non-profit.
            </p>
          </div>
          <div className="col-md-6">
            <div className="text-center">
              <img src={LogoSeattleSeawolves} alt="Seattle Seawolves Logo" width={180} height={180} />
            </div>
            <h5 className="text-center">
              <Link
                id="seattle-seawolves-link"
                to="/about-us/wafd-sports/seattle-seawolves"
                className="text-decoration-none"
              >
                Seattle Seawolves
                <Icon name="arrow-right" class="ml-2"></Icon>
              </Link>
            </h5>
            <p>
              We support{" "}
              <a
                id="seattle-compassion-link"
                rel="noopener noreferrer"
                target="_blank"
                href="https://seattlecompassion.com/"
              >
                Seattle Compassion
              </a>{" "}
              Services through the Seawolves by volunteering in the community and through financial donations to further
              their mission to house the unhoused, alleviate suffering and bring the community together to solve
              homelessness.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default CommunityPartnerships;
